import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const PageTemplate = ({ data }) => {
  const page = data.markdownRemark;
  
  return (
    <Layout>
      <div className="inline-block w-full px-5 py-10 md:p-10 leading-6">
        {/* <h1>{page.frontmatter.title}</h1> */}
        <div dangerouslySetInnerHTML={{ __html: page.html }} />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
